var Mustache = Mustache || {};
var site = site || {};

(function ($, Drupal) {
  Drupal.behaviors.slideContentPanelV1 = {
    queuedAction: false,
    overlay: {
      pc: {
        className: 'once-hub-overlay',
        transition: 'none'
      },
      mobile: {
        className: 'once-hub-overlay',
        width: '100%',
        height: '100%',
        innerHeight: '100%',
        scrolling: true,
        opacity: '0.9',
        transition: 'none'
      }
    },
    attach: function (context) {
      var self = this;
      var $modules = $('.js-slide-content-panel-v1', context);

      $modules.each(function () {
        var $module = $(this);
        var $cta = $('.js-elc-button', $module).first();
        var ctaIndex = parseInt($module.data('launch-button-index')) || 1;
        var $tempCta = $('.js-hero-link--' + ctaIndex + ' .js-elc-button', $module);
        var $onceHubBtn = $('.js-click-once-hub-pop-up', $module);
        var $onceHubLink = $('.js-elc-button', $onceHubBtn);
        var onceHubPath;

        try {
          onceHubPath = new URL($onceHubLink.attr('href')).pathname.substr(1);
        } catch {
          onceHubPath = '';
        }

        if ($tempCta.length) {
          $cta = $tempCta;
        }
        if (!$cta.length) {
          $module.addClass('not-attached');
        }
        $cta.off('click.reveal').on('click.reveal', function (event) {
          event.preventDefault();
          self.activateSlidePanel($(this).closest($('.js-slide-content-panel-v1')));
        });

        $onceHubBtn.once().on('click', function (e) {
          e.preventDefault();
          var content = self.getTemplateContent('once-hub-pop-up', false, onceHubPath);
          self.openPopUp(content, {
            onOpen: function () {
              $('body').addClass('once_hub_pop_up');
            }
          });
        });
      });
    },

    activateSlidePanel: function ($module) {
      var self = this;
      var $reveal = $($('.js-slide-content-panel-reveal', $module).html());

      $('.js-slide-content-panel-generated').remove();
      $('body').append($reveal);
      var $close = $('.js-slide-content-panel-close', $reveal);

      self.toggleAnimation(true);
      $close.off('click.close').on('click.close', function (e) {
        e.preventDefault();
        self.toggleAnimation(false);
      });
      $(document)
        .off('keyup.close')
        .on('keyup.close', function (e) {
          // escape key
          if (e.keyCode === 27) {
            self.toggleAnimation(false);
          }
        });
      Drupal.attachBehaviors($('.js-slide-content-panel-generated'));
      $(document).trigger('contentBlock.slidePanelRevealed', $module);
    },

    toggleAnimation: function (open) {
      var self = this;
      var $body = $('body');

      if (self.queuedAction) {
        clearTimeout(self.queuedAction);
      }
      if (open) {
        $body.addClass('active-content-panel');
        // Allows CSS transitions
        setTimeout(function () {
          $body.addClass('active-content-panel-animation');
          // Force carousel refresh
          $(window).trigger('resize');
        }, 1);
      } else {
        $body.removeClass('active-content-panel-animation');
        this.queuedAction = setTimeout(function () {
          $body.removeClass('active-content-panel');
          $('.js-slide-content-panel-generated').remove();
        }, 300);
      }
    },

    openPopUp: function (content, callBacks) {
      var self = this;
      content = self.isObj(content) ? content : $();

      if (!$.isFunction($.colorbox)) {
        return false;
      }

      var args = {
          html: content,
          scrolling: true
        },
        darg = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;
      $.extend(args, darg);

      // Set the arguments to initialize the pop up.
      if (self.isObj(callBacks)) {
        for (var i in callBacks) {
          var func = callBacks[i];
          if (self.isFunc(func)) {
            args[i] = func;
          }
        }
      }

      $.colorbox(args);

      if (site.client.isMobile) {
        $('#colorbox').css({
          position: 'fixed',
          padding: '0',
          top: '0',
          left: 'auto',
          width: '100%',
          height: '545px'
        });
        $('#cboxWrapper').css({ width: '100%', height: 'auto' });
        $('#cboxContent').css({ width: '100%', height: 'auto' });
        $('#cboxLoadedContent').css({ width: '100%', height: 'auto' });
      } else {
        $('#colorbox').css({
          position: 'fixed',
          padding: '0',
          top: '100px',
          left: '50%',
          width: '570px',
          height: '500px'
        });
        $('#cboxWrapper').css({ width: '570px', height: '500px' });
        $('#cboxContent').css({ width: '570px', height: '500px' });
        $('#cboxLoadedContent').css({ width: '570px', height: '500px', overflow: 'hidden' });
      }
    },

    getTemplateContent: function (key, data, className) {
      // Sanitize the incoming data.
      data = data || false;

      // Get the html content of the template.
      var html;
      if (className && className.length > 0) {
        html = $('script.inline-template.js-once-hub-' + className + "[path='" + key + "']").html();
      } else {
        html = $("script.inline-template[path='" + key + "']").html();
      }

      if (html.length === 0) {
        return $();
      }

      // If there's no data object passed in, then just return a basic jquery element.
      if (data === false || !$.isFunction(Mustache.render)) {
        return $(html);
      }

      // We have html and data, which means we should use Mustache to render the output.
      return $(Mustache.render(html, data));
    },

    isObj: function (mixed_var) {
      if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
        return false;
      }
      return mixed_var !== null && typeof mixed_var === 'object';
    },

    isFunc: function (func) {
      return typeof func === 'function';
    }
  };
})(jQuery, Drupal);
